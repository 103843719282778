<template>
 <div id="app_libraryrightclick">
  <v-bottom-sheet v-model="shower" v-if="imageHeight < 500">
      <v-list dense tile>
        
        <!-- <v-list-item-group v-model="item" color="primary"> -->
        <v-list-item v-for="(item, i) in items" :key="i" @click="goto(item.to)">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- </v-list-item-group> -->
      </v-list>
    </v-bottom-sheet>
    <v-menu :position-x="x"
      :position-y="y"
      offset-y
      close-on-content-click
      close-on-click
      transition="slide-y-transition" v-model="shower" v-else>
      <v-list dense>
        <!-- <v-list-item-group v-model="item" color="primary"> -->
        <v-list-item v-for="(item, i) in items" :key="i" @click="goto(item.to)">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- </v-list-item-group> -->
      </v-list>
    </v-menu>
    <previewfile
      :show="openpreviewfile"
      :filedata="file"
      :parentfolder="parentfolder"
      @closepreviewfile="openpreviewfile = false"
      @openpreviewfile="openpreviewfile = true"
    ></previewfile>
</div>   
</template>
<script>
import download from "downloadjs";
import { saveAs } from "file-saver";
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
const previewfile = () => import("../optional/dialog-preview");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});
export default {
    props:['AreaX','AreaY','parentfolder','show','file'],
    components:{previewfile},
    data: function() {
    return {
        openpreviewfile:false,
        showMenu:false,
        items: [
        {
          text: "libraryRclick.download",
          icon: "mdi-download",
          to: "download"
        },
        {
          text: "libraryRclick.preview",
          icon: "visibility",
          to: "preview"
        }
      ],
      x: 0,
      y: 0
    }
    }, computed: {
         ...mapState(["username", "authorize", "account_active","color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId"
    ]),
        imageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
     shower: {
      get() {
        if (this.show === true) {
          this.showmenus(); 
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          
        }
      }
    },
    },
    methods:{
         showmenus() {
      this.showMenu = false;
      this.x = this.AreaX;
      this.y = this.AreaY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    }, 
    goto(parameter) {
      if (parameter === "download") {
        this.$emit('downloadfile');
        console.log("download");       
        this.showMenu = false;
      } else if (parameter === "preview") {         
        this.openpreviewfile = true;
       // this.currentfile = this.file;
      }
    },
    }
}
</script>
<style>
#app_libraryrightclick {
  font-family: "Sarabun", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: white;
}
</style>